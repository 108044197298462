/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;600&display=swap');
*{
  font-display: swap;
}

/* Styles for the Stopwatch component */
.stopwatch-container {
  font-weight: 300;
  background-color: #1b2432;
  color: #eff1ed;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #eff1ed;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 350px;
  margin: auto;
  overflow: hidden; /* Ensure content doesn't overflow on mobile */
  position: relative; /* Required for the edit icon positioning */
  margin-bottom: 20px;
}

/* Label styles */
.stopwatch-label-container {
  display: flex;
  align-items: center; /* Align the label and icon vertically */
  justify-content: center; /* Center them horizontally */
}

.stopwatch-label {
  font-weight: 600;
  font-size: 24px;
  cursor: pointer; /* Indicates clickability */
  transition: color 0.3s ease; /* Smooth color transition */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* Edit icon styles */
.edit-icon {
  margin-left: 24px; /* Add margin to separate the icon from the label */
  cursor: pointer;
  color: #b1b4b1;
  transition: color 0.3s ease;
}

/* Color change for the edit icon on hover */
.stopwatch-container:hover .edit-icon {
  color: #eff1ed; /* Change color on hover */
}

/* Input field styles */
.stopwatch-container .label-container input {
  border: none;
  background: none;
  font-size: 24px;
  color: #eff1ed;
  outline: none;
  font-family: 'Comfortaa', Arial, Helvetica, sans-serif;
  width: 100%;
  display: block;
  margin: 0 auto;
  padding: 5px 0;
  transition: opacity 0.3s ease; /* Smooth fade-in effect */
  max-width: 100%;
}

/* Show the input field when in edit mode */
.stopwatch-container.editing .label-container input {
  opacity: 1;
}

.elapsed-time {
  padding: 24px;
  font-size: 24px;
  color: #b1b4b1;
  margin-bottom: 20px;
}

.button-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.control-button {
  background-color: #1b2432;
  color: #eff1ed;
  border: 1px solid #eff1ed;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 600;
  text-align: center;
}

.delete-button {
  background-color: #F990A6;
  color: #1b2432;
  border: 1px solid #eff1ed;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 600;
  text-align: center;
}

.delete-button:hover{
  background-color:#1b2432;
  color:#F990A6;
  border:1px solid #eff1ed;
  
  
}

.lap-list {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
}

.lap-list li {
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 5px;
}
