/* Global styling */
body {
  font-family: 'Comfortaa', Arial, Helvetica, sans-serif;
  font-display: auto;
  background-color: #121420;
  margin: 0;
  padding: 8px;
}

.app {
  margin: 1.618rem;
}

/* Header */
.header-container {
  text-align: center;
  margin-bottom: 1.618rem;
}

.header-title {
  font-size: 2rem;
  font-weight: 600;
  color: #EFF1ED;
  margin-bottom: 0.618rem;
}

.header-subtitle {
  font-size: 1.2rem;
  font-weight: 300;
  color: #EFF1ED;
  line-height: 1.618;
  margin-bottom: 0.618rem;
}

/* CTAs and Buttons */
.header-cta {
  padding: 0.5rem 1.618rem;
  background-color: #1B2432;
  color: #32A287;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 600;
  margin-top: 1.618rem;
  font-size: 1rem;
  display: inline-block;
  vertical-align: middle;
}

.header-cta:hover {
  background-color: #2D3A4A;
}

.descriptions {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  gap: 1.618rem;
  max-width: 900px;
  margin-inline: auto;
  @media only screen and (min-width: 600px) {
    flex-flow: row;
    align-items: start;
    justify-content: space-around;
  }
}