/* Define styles for the custom Button component */

.custom-button {
    background-color: #1b2432;
    color: #eff1ed;
    border: 1px solid #eff1ed;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-family: 'comfortaa', Arial, Helvetica, sans-serif;
    font-display: auto;
    font-size: 16px;
    transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  }
  
  .custom-button:hover {
    background-color: #2c394b; /* Darken the background color on hover */
    border-color: #2c394b; /* Darken the border color on hover */
  }
  