/* ./components/IntroHeader.css */

.intro-header-container {
    max-width: 66vw;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    color: #EFF1ED; /* Set font color to off white */
}

.intro-title {
    color: #EFF1ED;
    font-size: 1.8em; /* Adjust as needed */
    margin-bottom: 1em;
}

.intro-description {
    color: #EFF1ED;
    font-size: 1em; /* Adjust as needed */
    line-height: 1.618em;
}

