/* ./components/FeatureDescription.css */

.feature-container {
  text-wrap: balance;
  text-align: center;
  color: #eff1ed;
  max-width: 500px;
}

.feature-list {
  list-style-type: none;
  padding: 0;
}

.feature-list li {
  margin-bottom: 1em;
}

.feature-summary {
  margin-top: 2em;
}


.down-arrow {
  transform: rotate(90deg);
}

.icon {
  transition: transform 0.3s ease;
  margin-left: 4px;
  display: inline-block;
  vertical-align: middle;
}

.question {
  line-height: 1.618em;
  font-size: 16px;
  font-weight: 700;
  padding: 20px 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  margin: auto;
}

.answer {
  margin: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  display: block;
  line-height: 1.5;
  white-space: pre-wrap;
}
