.description-container {
    text-wrap: balance;
    text-align: center;
    color: #eff1ed;
    min-width: 275px;
  }
  
  .question {
    font-size: 16px;
    font-weight: 700;
    padding: 20px 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .icon {
    transition: transform 0.3s ease;
    margin-left: 4px;
    display: inline-block;
    vertical-align: middle;
  }
  
  .expanded-icon {
    transform: rotate(90deg);
  }
  
  .answer {
    padding: 15px 20px;
    margin: auto;
    max-width: 275px;
    font-size: 1.1em;
    font-weight: 500;
    line-height: 1.5;
    color: #EFF1ED;
    white-space: pre-wrap;
    text-align: left;
    background-color: #1B2432;
    border: 1px solid #EFF1ED;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Additional Styles to Enhance Interaction */
  .question:hover {
    background-color: rgba(32, 32, 32, 0.1);
    border-radius: 8px;
  }
  
  .icon:hover {
    transform: scale(1.1);
  }
  