/* CTAButton.css */

.cta-button {
  font-family: 'Comfortaa', Arial, Helvetica, sans-serif;
  font-display: auto;
  font-weight: 600;
  padding: 0.618rem 1.618rem;
  background-color: #21A179;
  color: #121420;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 600; /* Use font-weight 600 */
  font-size: 1.15rem;
  display: block; /* Use block to center text */
  margin: 0 auto; /* Center align the button horizontally */
  margin-top: 1.618rem;
  margin-bottom: 1.618rem;
  text-align: center; /* Center align the text */
}

.cta-button:hover {
  background-color: #32A287;
  transform: scale(1.05); /* Corrected the property name */
}
